<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="page terms">
    <section class="banner">
      <div class="container">
        <h2 v-text="$t('Privacy Policy')"></h2>
      </div>
    </section>
    <section class="page__content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ol>
              <li>
                <h3 v-text="$t('General (title)')"></h3>
                <div v-html="$t('General (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('Types of Data collected (title)')"></h3>
                <div v-html="$t('Types of Data collected (description)')"></div>
              </li>
              <li>
                <h3
                  v-text="
                    $t('User Generated Content and Public Activities (title)')
                  "
                ></h3>
                <div
                  v-html="
                    $t(
                      'User Generated Content and Public Activities (description)'
                    )
                  "
                ></div>
              </li>
              <li>
                <h3 v-text="$t('Disclosure of Your Information (title)')"></h3>
                <div
                  v-html="$t('Disclosure of Your Information (description)')"
                ></div>
              </li>
              <li>
                <h3 v-text="$t('How We Protect Your Information (title)')"></h3>
                <div
                  v-html="$t('How We Protect Your Information (description)')"
                ></div>
              </li>
              <li>
                <h3
                  v-text="$t('Your Choices About Your Information (title)')"
                ></h3>
                <div
                  v-html="
                    $t('Your Choices About Your Information (description)')
                  "
                ></div>
              </li>
              <li>
                <h3 v-text="$t('Links (title)')"></h3>
                <div v-html="$t('Links (description)')"></div>
              </li>
              <li>
                <h3
                  v-text="$t('Mode and place of processing the Data (title)')"
                ></h3>
                <div
                  v-html="
                    $t('Mode and place of processing the Data (description)')
                  "
                ></div>
              </li>
              <li>
                <h3 v-text="$t('The rights of Users (title)')"></h3>
                <div v-html="$t('The rights of Users (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('Changes to this privacy policy (title)')"></h3>
                <div
                  v-html="$t('Changes to this privacy policy (description)')"
                ></div>
              </li>
              <li>
                <h3
                  v-text="$t('Information about this privacy policy (title)')"
                ></h3>
                <div
                  v-html="
                    $t('Information about this privacy policy (description)')
                  "
                ></div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import '../terms/terms.scss';
</style>
