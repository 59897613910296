var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page terms"},[_c('section',{staticClass:"banner"},[_c('div',{staticClass:"container"},[_c('h2',{domProps:{"textContent":_vm._s(_vm.$t('Privacy Policy'))}})])]),_c('section',{staticClass:"page__content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ol',[_c('li',[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('General (title)'))}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('General (description)'))}})]),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('Types of Data collected (title)'))}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('Types of Data collected (description)'))}})]),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(
                  _vm.$t('User Generated Content and Public Activities (title)')
                )}}),_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.$t(
                    'User Generated Content and Public Activities (description)'
                  )
                )}})]),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('Disclosure of Your Information (title)'))}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('Disclosure of Your Information (description)'))}})]),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('How We Protect Your Information (title)'))}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('How We Protect Your Information (description)'))}})]),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('Your Choices About Your Information (title)'))}}),_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.$t('Your Choices About Your Information (description)')
                )}})]),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('Links (title)'))}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('Links (description)'))}})]),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('Mode and place of processing the Data (title)'))}}),_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.$t('Mode and place of processing the Data (description)')
                )}})]),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('The rights of Users (title)'))}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('The rights of Users (description)'))}})]),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('Changes to this privacy policy (title)'))}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('Changes to this privacy policy (description)'))}})]),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('Information about this privacy policy (title)'))}}),_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.$t('Information about this privacy policy (description)')
                )}})])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }